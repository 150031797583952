/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useState } from 'react';
import ChromePicker from 'react-color';

import useClassy from '@core/hooks/useClassy';
import useDebounced from '@core/hooks/useDebounced';

import Button from '../Button';

import classes from './style.module.scss';

const hexRegex = /^#([0-9a-f]{3}|[0-9a-f]{6})$/i;
type BttnSizeType = 'lg' | 'md' | 'sm' | 'xs' | undefined;

interface ColorEvent {
  hex: string;
}

interface ColorPickerProps {
  buttonStyle?: React.CSSProperties;
  circular?: boolean;
  className?: string;
  color?: string | null;
  debounceOnChange?: number;
  id?: string | null;
  onChange: (color: string) => void;
  placeholder?: string | null;
  size?: 'lg' | 'md' | 'sm';
  style?: React.CSSProperties;
  wrapperClassName?: string;
}

function ColorPicker({
  buttonStyle,
  color,
  circular,
  className = '',
  debounceOnChange = 0,
  placeholder,
  onChange,
  size = 'md',
  style,
  wrapperClassName = '',
}: ColorPickerProps) {
  const bem = useClassy(classes, 'ColorPicker');
  const [currentColor, setCurrentColor] = useState(color || placeholder || '#018EF5');
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const onChangeDebounced = useDebounced((c: string) => onChange(c), debounceOnChange);
  const isPlaceholderShown = placeholder && (currentColor === placeholder || currentColor === '');

  useEffect(() => {
    if (color) {
      const isValidHex = hexRegex.test(color);
      setCurrentColor(isValidHex ? color : '');
    } else if (color === '') {
      setCurrentColor('');
    }
  }, [color]);

  const handleDrag = useCallback(
    (c: ColorEvent) => {
      setCurrentColor(c.hex);
      if (typeof onChange === 'function' && !debounceOnChange) onChange(c.hex);
    },
    [debounceOnChange, onChange],
  );

  const handleChange = useCallback(
    (c: ColorEvent) => {
      if (typeof onChange === 'function' && debounceOnChange) {
        onChangeDebounced(c.hex);
      }
    },
    [debounceOnChange, onChangeDebounced, onChange],
  );

  const toggleColorPickerDisplay = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  useEffect(() => {
    const onKeydown = e => {
      if (e.key === 'Escape') {
        handleClose();
      }
    };
    window?.addEventListener('keydown', onKeydown);
    return () => window?.removeEventListener('keydown', onKeydown);
  });

  return (
    <div className={bem('&', className, `_${size}`)} style={style}>
      <Button
        className={bem('&-button')}
        data-testid="ColorPicker-Button"
        kind="secondary"
        onClick={toggleColorPickerDisplay}
        outline={true}
        size={size as BttnSizeType}
        style={buttonStyle}
      >
        <div
          className={bem('&-swatch', circular && '&-swatch-circle', wrapperClassName)}
          style={{ backgroundColor: `${currentColor || placeholder}` }}
        />
        <div className={bem('&-value', isPlaceholderShown && '&-value-placeholder')} data-testid="ColorPicker-Value">
          {currentColor || placeholder}
        </div>
      </Button>

      {!!displayColorPicker && (
        <div className={bem('-popover')}>
          <div className={bem('&-cover')} onClick={handleClose} />
          <ChromePicker
            className={bem('&-input')}
            color={currentColor}
            disableAlpha={true}
            onChange={handleDrag}
            onChangeComplete={handleChange}
          />
        </div>
      )}
    </div>
  );
}

export default React.memo(ColorPicker);
