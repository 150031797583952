import type { RecipeReadType } from '@readme/api/src/mappings/recipe/types';
import type { TutorialSchema } from '@readme/backend/models/tutorial/types';

import { useContext, useMemo } from 'react';

import type { VersionContextValue } from '@core/context';
import { VersionContext } from '@core/context';
import type legacyUseReadmeApi from '@core/hooks/deprecated/useReadmeApi';
import { useProjectStore, useSuperHubStore } from '@core/store';

import {
  normalizeDeleteTutorialResponse,
  normalizeGetTutorialResponse,
  normalizeGetTutorialsResponse,
  normalizeUpsertTutorialRequestBody,
  normalizeUpsertTutorialResponse,
} from './util';

type InitRequestType = ReturnType<typeof legacyUseReadmeApi>['initRequest'];
type LegacyOrNextTutorial = Partial<TutorialSchema> | RecipeReadType['data'];

function isLegacyTutorial(data: LegacyOrNextTutorial): data is TutorialSchema {
  return '_id' in data;
}

/**
 * Returns a set of API actions for Recipes.
 * If the user is a SuperHub user, the API actions will map to their respective APIv2 endpoints.
 * If the user is not a SuperHub user, the API actions will map to their respective legacy API endpoints.
 */
export default function useApiActions() {
  const { version_clean: version } = useContext(VersionContext) as VersionContextValue;
  const isSuperHub = useSuperHubStore(s => s.isSuperHub);
  const isRecipesEnabled = useProjectStore(s => s.data.appearance.navigation.links.recipes.visibility);

  return useMemo(
    () => ({
      createTutorial: (initRequest: InitRequestType, data: LegacyOrNextTutorial) => {
        const requestOptions = isSuperHub
          ? {
              method: 'POST',
              path: `versions/${version}/recipes`,
              body: JSON.stringify(normalizeUpsertTutorialRequestBody(data)),
              transformResponse: normalizeUpsertTutorialResponse,
            }
          : {
              method: 'POST',
              path: 'recipes',
              body: JSON.stringify(data),
            };

        return initRequest(requestOptions);
      },

      deleteTutorial: (initRequest: InitRequestType, data: LegacyOrNextTutorial) => {
        const requestOptions =
          isLegacyTutorial(data) && !isSuperHub
            ? { method: 'DELETE', path: `recipes/${data._id}` }
            : {
                method: 'DELETE',
                path: `versions/${version}/recipes/${data.slug}`,
                transformResponse: normalizeDeleteTutorialResponse,
              };

        return initRequest(requestOptions);
      },

      getCodeSnippets: (initRequest: InitRequestType, data: unknown) => {
        return isSuperHub
          ? () => {
              /**
               * @todo add an APIv2 version for this action once there is an implementation for it
               */
            }
          : initRequest({
              method: 'POST',
              path: 'recipes/getCodeSnippets',
              body: JSON.stringify(data),
            });
      },

      getCodeString: (initRequest: InitRequestType, data: unknown) => {
        return isSuperHub
          ? () => {
              /**
               * @todo add an APIv2 version for this action once there is an implementation for it
               */
            }
          : initRequest({
              method: 'POST',
              path: 'recipes/getCodeString',
              body: JSON.stringify(data),
            });
      },

      getEndpoints: (initRequest: InitRequestType) => {
        return isSuperHub
          ? () => {
              /**
               * @todo add an APIv2 version for this action once there is an implementation for it
               */
            }
          : initRequest({
              method: 'GET',
              path: 'recipes/endpoints',
            });
      },

      getTutorial: (initRequest: InitRequestType, slug: string) => {
        const requestOptions = isSuperHub
          ? {
              method: 'GET',
              path: `versions/${version}/recipes/${slug}`,
              transformResponse: normalizeGetTutorialResponse,
            }
          : {
              method: 'GET',
              path: `recipes/${slug}/data`,
            };

        return initRequest(requestOptions);
      },

      getTutorials: (initRequest: InitRequestType) => {
        const requestOptions = isSuperHub
          ? {
              method: 'GET',
              path: `versions/${version}/recipes`,
              transformResponse: normalizeGetTutorialsResponse,
            }
          : {
              method: 'GET',
              path: 'recipes/data',
            };

        return initRequest(requestOptions);
      },

      makeHero: (initRequest: InitRequestType, data: LegacyOrNextTutorial) => {
        const requestOptions =
          isLegacyTutorial(data) && !isSuperHub
            ? {
                method: 'POST',
                path: `recipes/${data._id}/makeHero`,
              }
            : {
                method: 'PATCH',
                path: `versions/${version}/recipes/${data.slug}`,
                body: JSON.stringify({
                  // APIv2 considers the "hero" to be the first recipe in the array,
                  // so we update the position to 0 to make it the hero
                  position: 0,
                  privacy: { view: 'public' },
                }),
                transformResponse: normalizeUpsertTutorialResponse,
              };

        return initRequest(requestOptions);
      },

      shiftOrder: (initRequest: InitRequestType, data, index: number) => {
        const requestOptions = isSuperHub
          ? {
              method: 'PATCH',
              path: `versions/${version}/recipes/${data.slug}`,
              body: JSON.stringify({
                // The Recipes ui calls the shiftOrder action with the direction as a -1/1 delta,
                // but APIv2 expects the new position as an index
                position: index + data.direction,
              }),
              transformResponse: normalizeUpsertTutorialResponse,
            }
          : {
              method: 'POST',
              path: `recipes/${data._id}/shiftOrder`,
              body: JSON.stringify(data),
            };
        return initRequest(requestOptions);
      },

      toggleModule: (initRequest: InitRequestType) => {
        const requestOptions = isSuperHub
          ? {
              method: 'POST',
              path: 'projects/me',
              body: JSON.stringify({
                appearance: {
                  navigation: { links: { recipes: { visibility: isRecipesEnabled ? 'disabled' : 'enabled' } } },
                },
              }),
            }
          : {
              method: 'POST',
              path: 'recipes/toggleModule',
            };

        return initRequest(requestOptions);
      },

      updateTutorial: (initRequest: InitRequestType, data: LegacyOrNextTutorial) => {
        const requestOptions =
          isLegacyTutorial(data) && !isSuperHub
            ? {
                method: 'PUT',
                path: `recipes/${data._id}`,
                body: JSON.stringify(data),
              }
            : {
                method: 'PATCH',
                path: `versions/${version}/recipes/${data.slug}`,
                body: JSON.stringify(normalizeUpsertTutorialRequestBody(data)),
                transformResponse: normalizeUpsertTutorialResponse,
              };

        return initRequest(requestOptions);
      },

      updateOnboarding: (
        initRequest: InitRequestType,
        data: {
          endpoints?: boolean;
          published?: boolean;
        },
      ) => {
        return isSuperHub
          ? () => {} // Return a no-op, there is no APIv2 equalivent for storing `recipes/onboarding` data
          : initRequest({
              method: 'POST',
              path: 'recipes/onboarding',
              body: JSON.stringify(data),
            });
      },
    }),
    [isRecipesEnabled, isSuperHub, version],
  );
}
